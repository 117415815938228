import { Divider, Heading } from "@chakra-ui/react";
import clsx from "clsx";
import { format } from "date-fns";
import { CheckCircle, CheckCircle2, ShieldCheck } from "lucide-react";
import React from "react";
import { formatToNaira } from "../../helpers/utils/funtions";
import { ProPortfolioJob } from "../../types";
import { Rating } from "./rating";

interface WorkHistoryItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  job: ProPortfolioJob;
}

export function WorkHistoryItem({ job, className }: WorkHistoryItemProps) {
  const name = job.tasks.map((task) => task.name).join(" & ");

  const { rating } = job.review || {};

  let combinedRating = 0;

  if (rating) {
    const {
      appearance,
      behavior,
      punctuality,
      properToolUsage,
      postServiceCleanUp,
    } = rating;

    combinedRating =
      (appearance +
        behavior +
        punctuality +
        properToolUsage +
        postServiceCleanUp) /
      5;
  }

  return (
    <div className={clsx("flex flex-col gap-4 border-b py-4", className)}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col lg:flex-row items-start lg:items-center  gap-1">
          <Heading as="h4" fontSize={18} className="font-medium m-0">
            {name}
          </Heading>

          <div className="flex  items-center gap-1">
            <ShieldCheck className="fill-flatteredFlamingo-500 stroke-white" />
            <p>Verified</p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-1 lg:gap-4">
          <div className="flex items-center gap-2 lg:gap-4">
            {job.review && (
              <div className="flex items-center gap-1">
                <Rating size="sm" rating={combinedRating} />
                <p>{combinedRating.toFixed(2)}</p>
              </div>
            )}

            <Divider orientation="vertical" />
            <p>Budget: {formatToNaira(job.budget)}</p>
          </div>

          <Divider orientation="vertical" />
          <p>{format(new Date(job.date), "MMM dd, yyyy")}</p>
        </div>
      </div>
      <p className="text-sm text-gray-500">
        {job.review?.feedback || "No feedback yet"}
      </p>
    </div>
  );
}

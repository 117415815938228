import { Category, SubscriptionPackage, Plan } from "./pages/dashboard/types";

export interface AuthUser {
  name?: string;
  email?: string;
  phoneNumber?: string;
  roles: string[];
}

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface Client {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  addresses: Address[];
}

export interface Payment {
  id: string;
  transactionReference: string;
  transactionDate: Date;
  status: TransactionStatus;
  amount: number;
}

export interface OrderUser {
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
}

export interface FormContact {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface Location {
  id: string;
  buildingNumber: string;
  streetName: string;
  landmark: string;
  area: string;
  location: string;
  city: string;
}

export enum Status {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  ARCHIVED = "ARCHIVED",
}

export enum Availability {
  AVAILABLE = "AVAILABLE",
  TENTATIVE = "TENTATIVE",
  ENGAGED = "ENGAGED",
}

export enum JobProgress {
  NOT_STARTED = "NOT_STARTED",
  PRE_INSPECTION = "PRE_INSPECTION",
  FINAL_QUOTE = "FINAL_QUOTE",
  MATERIALS_PURCHASED = "MATERIALS_PURCHASED",
  CLIENT_INSPECTION = "CLIENT_INSPECTION",
  COMPLETED = "COMPLETED",
}

export interface JobProgressLog {
  user: string;
  update: JobProgress;
}

export interface Task {
  id: string;
  description: string;
  name: string;
  estimatedCost: number;
  requestCount: number;
  categoryId: string;
  subCategoryId: string;
  imageUrl: string;
}

export interface Tradesman {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: Location;
  level: number;
  imageUrl: string;
  categoryId: string;
  rating: string;
  rewardPoints: number;
  referralCode: string;
  availability?: Availability;
}

export interface Contact {
  notes?: string;
  location: Location;
}

export interface JobCustomTask {
  name: string;
  description: string;
  resourceUrl: string;
  subCategoryId: string;
  categoryId: string;
}

export interface InvoiceMaterial {
  name: string;
  unitPrice: number;
  quantity: number;
}

export interface Job {
  id: string;
  jobCode: string;
  custom?: JobCustomTask;
  assignedTradesman?: Tradesman;
  task: Task;
  finalCost: number;
  status: Status;
  scheduledDate: Date;
  progress: JobProgress;
  completedAt: number;
  payment?: Payment;
  contact: Contact;
  progressLog?: JobProgressLog[];
  invoiceDetails?: {
    materials: InvoiceMaterial[];
    labor: number;
  };
}

interface Recipient {
  name: string;

  address: string[];

  phoneNumber: string;

  email: string;
}

interface InvoiceItem {
  name: string;

  amount: number;

  description: string[];
}
export enum InvoiceType {
  INSPECTION = "INSPECTION",
  MATERIALS = "MATERIALS",
  MARKETPLACE_REQUEST_MATCH = "MARKETPLACE_REQUEST_MATCH",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export interface InvoiceDiscount {
  id: string;
  code: string;
  description: string;
  value: number;
  balance: number | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface Invoice {
  id: string;

  invoiceNumber: number;

  type: InvoiceType;

  inspectionAmount: number;

  invoiceDate: Date;

  recipient: Recipient;

  discount: number; // float below 1.0 for discount percentage

  appliedDiscount: InvoiceDiscount | null;

  assignments: TradesmanAssignment[];

  payment: Payment | null;

  sent?: boolean;

  canceled?: boolean;

  splitPayments?: {
    percentage: number;
    payment?: Payment;
  }[];

  isFullyPaid?: boolean;

  hasAtLeastOnePayment?: boolean;
}

export enum AssignmentProgress {
  NOT_STARTED = "NOT_STARTED",
  PRE_INSPECTION = "PRE_INSPECTION",
  FINAL_QUOTE = "FINAL_QUOTE",
  MATERIALS_PURCHASED = "MATERIALS_PURCHASED",
  CLIENT_INSPECTION = "CLIENT_INSPECTION",
  COMPLETED = "COMPLETED",
  ASSIGNED = "ASSIGNED",
  UN_ASSIGNED = "UN_ASSIGNED",
}

export interface TradesmanAssignment {
  id: string;
  inspectionPayment?: Payment;
  inspectionWaived?: boolean;
  jobs: Job[];
  tradesman: Tradesman;
  date: Date;
  progress: AssignmentProgress;
  invoice: Invoice | null;
}

export interface Order {
  id: string;
  orderCode: string;
  inspectionPayment: Payment;
  jobs: Job[];
  locationSchedules: {
    scheduledDate: Date;
  }[];
  client: OrderUser;
  createdByUid: string;
  assignments: TradesmanAssignment[];
  createdAt: Date;
  isEmergency: boolean;
  suggestedTradesmen?: Tradesman[];
}

export interface IJobInitialState {
  message: string | null;
  error: string | null;
  status: "idle" | "pending" | "error";
  job: Job | null;
  tradesmen: Tradesman[];
}

export interface IAssignmentInitialState {
  message: string | null;
  error: string | null;
  status: "idle" | "pending" | "error";
  availableTradesmenInCurrentOrder: Tradesman[];
  fetchedAssignments: {
    [x: string]: TradesmanAssignment;
  };
  create: {
    date: number | null;
    jobIds: string[];
    orderId: string;
    tradesman: Tradesman | null;
  };
  currentAssignmentPayment: {
    modalOpen: boolean;
    paymentUrl: string;
    transactionReference: string;
  };
  editInvoice: {
    modalOpen: boolean;
  };
}

export type OrderStatus =
  | "COMPLETED"
  | "IN_PROGRESS"
  | "PROCESSING"
  | "CANCELLED";

export type Assigned = "yes" | "no" | "partial";

export interface ClientOrder {
  id: string;

  orderCode: string;

  categories: string[];

  taskCount: number;

  status: OrderStatus;

  assigned: "yes" | "no" | "partial";

  inspectionFeePaid: boolean;

  totalCost?: number;

  createdAt: Date;
}

export type FieldValue<T> = {
  value: T;
  isValid: boolean;
};

export interface WaitlistedContact {
  name: string;

  email: string;

  phoneNumber: string;

  emailVerified: boolean;
}

export interface WaitlistedBusiness {
  id: string;

  name: string;

  contacts: WaitlistedContact[];

  createdAt: Date;

  updatedAt: Date;
}

export interface Point {
  type: "Point";
  coordinates: number[];
}

export interface Address {
  id: string;
  address: string;
  city: string;
  state: string;
  location: Point;
  createdAt: Date;
  updatedAt: Date;
}

export interface Subscription {
  id: string;

  category: Category;

  plan: Plan;

  client: Client;

  homeOwnerRequest: HomeownerRequest;

  lastBillingDate: Date;

  createdAt: Date;

  updatedAt: Date;
}

export interface HomeownerRequest {
  id: string;
  client: Client | null;
  category: Category;
  otherCategoryText: string | null;
  isSubscriptionRequest: boolean | null;
  plan: Plan | null;
  isPaid: boolean | null;
  subscription: Subscription | null;
  invoice: Invoice | null;
  description: string;
  email: string | null;
  address: Address | null;
  //frequency: HomeownerRequestTaskFrequency | null;
  startDate: Date | null;
  firstName: string | null;
  lastName: string | null;
  leadSource: LeadSource | null;
  otherLeadSourceText: string | null;
  phoneNumber: string | null;
  isComplete: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum LeadSource {
  Social_Media = "Social_Media",
  Friend_or_Referral = "Friend_or_Referral",
  Online_Search = "Online_Search",
  Advertisement = "Advertisement",
  Other = "Other",
}

export type AddressPayload = Omit<
  Address,
  "id" | "location" | "createdAt" | "updatedAt"
> & {
  id?: string;
  coordinates: number[];
};

export enum HomeownerRequestTaskFrequency {
  ONCE = "ONCE",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export interface ProPortfolioJobTask {
  name: string;

  description: string;
}

export interface ProPortfolioCertification {
  name: string;

  issuedBy: string;

  issuedAt: Date;
}

export interface ProPortfolioJob {
  tasks: ProPortfolioJobTask[];
  budget: number;

  review: Review | null;

  date: Date;
}

export interface ProPortfolio {
  firstName: string;
  lastName: string;

  email?: string;

  phone?: string;

  title?: string;

  location: string | null;

  jobs: ProPortfolioJob[];

  certifications: ProPortfolioCertification[];

  ratingCount: number;

  averageRating: number;

  totalBudget: number;

  overview: string;
}

interface Rating {
  punctuality: number;

  appearance: number;

  behavior: number;

  properToolUsage: number;

  postServiceCleanUp: number;
}

export interface Review {
  id: string;
  rating: Rating;
  feedback: string;
}

import {
  Button,
  Heading,
  HStack,
  Icon,
  StackProps,
  Text,
} from "@chakra-ui/react";
import clsx from "clsx";
import { logEvent } from "helpers/analytics";
import React, { useEffect, useMemo } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

interface FixmeBannerProps extends StackProps {}

export const FixmeBanner = ({ ...stackProps }: FixmeBannerProps) => {
  const location = useLocation();

  const shouldDisplay = useMemo(() => {
    const hiddenPages: string[] = [""];
    const hiddenPaths = [/^[/@].+/];

    console.log(location.pathname);

    const isHiddenPath = hiddenPaths.some((path) =>
      path.test(location.pathname)
    );

    const isHiddenPage = hiddenPages.includes(location.pathname);

    return !isHiddenPath && !isHiddenPage;
  }, [location.pathname]);

  useEffect(() => {
    if (shouldDisplay) {
      logEvent("fixme_banner_claim_voucher_displayed");
    }
  }, [shouldDisplay]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <HStack w="full" bgColor="white" justifyContent="center" px={5}>
      <div className="max-w-screen-xl bg-flatteredFlamingo-500 w-full p-4 md:p-8 flex flex-col gap-4">
        <div className="flex flex-col gap-2 md:gap-4 items-start">
          <span className="text-flatteredFlamingo-500 rounded-full bg-white border-white border px-4 py-2 text-sm md:text-base font-semibold font-[AeonikPro] tracking-wider uppercase">
            🎉 Limited Time Offer
          </span>
          <Heading
            as="h2"
            fontSize={{
              base: 18,
              lg: 24,
            }}
            color="white"
            fontWeight="semibold"
            lineHeight={{
              base: "base",
              lg: "shorter",
            }}
          >
            Claim{" "}
            <span className="proportional-nums font-[AeonikPro]">₦10,000</span>{" "}
            Worth of Free Home and Office Repairs
          </Heading>
          <Text className=" text-white text-sm md:text-lg">
            Sign up with a referral code and request a service on LaborHack
            today to enjoy free home and office repairs worth ₦10,000!. Offer
            valid for a limited time only!
          </Text>
        </div>

        <div className="flex w-full justify-end">
          <Button
            id="claim-voucher-btn"
            as={RouterLink}
            to="/fixme?openSignup=true"
            color="white"
            fontFamily="AeonikPro"
            fontSize={{
              base: 14,
              lg: 20,
            }}
            _hover={{
              color: "white",
              textDecoration: "none",
            }}
            fontWeight="medium"
            rightIcon={
              <Icon
                as={BsArrowRight}
                w={6}
                h={6}
                ml={2}
                className="animate-wiggle"
              />
            }
            variant="link"
            className={clsx(
              `border-b-4 border-transparent hover:border-white !pb-2 !px-1`
            )}
            onClick={() => {
              logEvent("fixme_banner_claim_voucher_clicked");
            }}
          >
            Claim your voucher
          </Button>
        </div>
      </div>
    </HStack>
  );
};

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import clsx from "clsx";
import { isBefore } from "date-fns";
import React, { useMemo, useState } from "react";
import { ProPortfolioJob } from "../../types";
import { WorkHistoryItem } from "./work-history-item";

const itemsPerPage = 5;

interface WorkHistoryProps {
  jobs: ProPortfolioJob[];
}

const constructKey = (job: ProPortfolioJob) => {
  return (
    job.tasks.map((task) => task.name).join("") +
    job.date.toString() +
    job.budget.toString()
  );
};

export function WorkHistory({ jobs: unorderedJobs }: WorkHistoryProps) {
  const jobs = useMemo(() => {
    return unorderedJobs
      .slice()
      .sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));
  }, [unorderedJobs]);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const currentPageJobs = jobs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-4">
        {currentPageJobs.map((job, index, array) => (
          <WorkHistoryItem
            key={constructKey(job)}
            job={job}
            className={clsx(
              index === array.length - 1 && totalPages === 1 && "border-b-0"
            )}
          />
        ))}
      </div>

      {totalPages > 1 && (
        <div className="flex justify-end items-center gap-4">
          <Button
            isDisabled={currentPage === 1}
            leftIcon={<ChevronLeftIcon />}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>

          <p>
            {currentPage} of {totalPages}
          </p>

          <Button
            isDisabled={currentPage === totalPages}
            rightIcon={<ChevronRightIcon />}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

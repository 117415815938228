import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { Global } from "@emotion/react";
import { Avatar, Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { Check, CheckCircle2, Copy, MapPin } from "lucide-react";
import clsx from "clsx";
import { formatToNaira } from "../../helpers/utils/funtions";
import { WorkHistory } from "../../features/pro-portfolio/work-history";
import { Rating } from "../../features/pro-portfolio/rating";
import { Helmet } from "react-helmet";
import { ProPortfolio } from "../../types";
import { format } from "date-fns";

const GET_PORTFOLIO = gql`
  query ProPortfolio($handle: String!) {
    proPortfolio(handle: $handle) {
      firstName
      lastName
      phone
      email
      jobs {
        date
        tasks {
          name
          description
        }
        budget
        review {
          feedback
          rating {
            appearance
            behavior
            punctuality
            properToolUsage
            postServiceCleanUp
          }
        }
      }
      certifications {
        name
        issuedBy
        issuedAt
      }
      title
      location
      totalBudget
      averageRating
      ratingCount
      overview
    }
  }
`;

export default function ProProfilePage() {
  const { handle } =
    useParams<{
      handle: string;
    }>();

  const { pathname } = useLocation();

  const url = `${window.location.origin}${pathname}`;

  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const { data, loading, error } = useQuery<{
    proPortfolio: ProPortfolio;
  }>(GET_PORTFOLIO, {
    variables: { handle },
  });

  const {
    punctuality,
    appearance,
    behavior,
    properToolUsage,
    postServiceCleanUp,
  } = useMemo(() => {
    const response = {
      punctuality: 0,
      appearance: 0,
      behavior: 0,
      properToolUsage: 0,
      postServiceCleanUp: 0,
    };

    if (data) {
      const jobsWithReviews = data.proPortfolio.jobs.filter(
        (job) => job.review
      );

      jobsWithReviews.forEach((job, index) => {
        if (job.review) {
          const {
            appearance,
            behavior,
            punctuality,
            properToolUsage,
            postServiceCleanUp,
          } = job.review.rating;

          response.punctuality =
            (response.punctuality * index + punctuality) / (index + 1);
          response.appearance =
            (response.appearance * index + appearance) / (index + 1);
          response.behavior =
            (response.behavior * index + behavior) / (index + 1);
          response.properToolUsage =
            (response.properToolUsage * index + properToolUsage) / (index + 1);
          response.postServiceCleanUp =
            (response.postServiceCleanUp * index + postServiceCleanUp) /
            (index + 1);
        }
      });
    }

    return response;
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <RequestFailed />;

  if (!data) return <RequestFailed />;

  const noOfJobsCompleted = data.proPortfolio.jobs.length;

  return (
    <>
      <Helmet>
        <title>
          {data.proPortfolio.firstName} {data.proPortfolio.lastName?.[0]}. |
          LaborHack
        </title>
      </Helmet>
      <Global
        styles={`
       body {
          background-color: white !important;
        }
      `}
      />
      <HStack w="full" bgColor="white" justifyContent="center">
        <Stack
          px={{
            base: 0,
            lg: 5,
          }}
          py={{
            base: 8,
            lg: 20,
          }}
          spacing={{
            base: 12,
            lg: 0,
          }}
          direction={{
            base: "column",
            lg: "row",
          }}
          w="full"
          maxW="container.xl"
          alignItems={{
            base: "start",
            lg: "center",
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-col lg:flex-row lg:justify-between gap-8 border px-6 py-9">
              <div className="flex items-start gap-5">
                <Avatar
                  bg="flatteredFlamingo.500"
                  textColor="white"
                  size={{
                    base: "xl",
                    lg: "2xl",
                  }}
                  name={`${data.proPortfolio.firstName} ${data.proPortfolio.lastName[0]}.`}
                  src={""}
                />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <Heading
                      as="h2"
                      fontSize={{
                        base: 24,
                        lg: 28,
                      }}
                    >
                      {data.proPortfolio.firstName}{" "}
                      {data.proPortfolio.lastName[0]}.
                    </Heading>
                    <p className="font-medium text-lg">
                      {data.proPortfolio.title}
                    </p>
                  </div>

                  {data.proPortfolio.location && (
                    <div className="flex items-center gap-2">
                      <MapPin className="w-5 h-5 stroke-gray-400" />
                      <p className="text-sm">{data.proPortfolio.location}</p>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <Rating rating={data.proPortfolio.averageRating} />
                    <p className="text-sm">
                      {data.proPortfolio.averageRating &&
                        data.proPortfolio.averageRating.toFixed(2)}{" "}
                      | {data.proPortfolio.ratingCount} reviews
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-2 w-full lg:w-auto">
                {copied ? (
                  <Button
                    disabled
                    rightIcon={<Check className="w-5 h-5" />}
                    colorScheme="flatteredFlamingo"
                    size="lg"
                    variant="ghost"
                    className={clsx("w-full order-2 lg:order-1 lg:w-auto")}
                  >
                    Copied
                  </Button>
                ) : (
                  <Button
                    rightIcon={<Copy className="w-5 h-5" />}
                    colorScheme="flatteredFlamingo"
                    size="lg"
                    variant="ghost"
                    className={clsx("w-full order-2 lg:order-1 lg:w-auto")}
                    onClick={handleCopy}
                  >
                    Copy Link
                  </Button>
                )}

                {false && (
                  <Button
                    colorScheme="flatteredFlamingo"
                    size="lg"
                    className={clsx("w-full order-1 lg:order-2 lg:w-auto")}
                  >
                    Hire {data?.proPortfolio.firstName}
                  </Button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 border-l border-r border-b">
              <div className="col-span-1 flex flex-col">
                <div className="p-6 grid grid-cols-[auto_1fr] gap-y-2 gap-x-4 border-b">
                  <p>Punctuality</p>
                  <Rating rating={punctuality} />
                  <p>Appearance</p>
                  <Rating rating={appearance} />
                  <p>Behavior</p>
                  <Rating rating={behavior} />
                  <p>Proper Tool Usage</p>
                  <Rating rating={properToolUsage} />
                  <p>Post Service Clean Up</p>
                  <Rating rating={postServiceCleanUp} />
                </div>
                <div className="p-6 flex gap-8 border-b">
                  <div className="flex flex-col gap-2">
                    <Heading as="h3" fontSize={20}>
                      {noOfJobsCompleted > 100 ? `100+` : noOfJobsCompleted}
                    </Heading>
                    <p className="text-sm font-medium text-gray-500">
                      Jobs Completed
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Heading as="h3" fontSize={20}>
                      {formatToNaira(data.proPortfolio.totalBudget)}
                    </Heading>
                    <p className="text-sm font-medium text-gray-500">
                      Total Budget
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-2 border-l flex flex-col">
                <div className="p-6 flex flex-col gap-4 border-b items-start">
                  <Heading
                    as="h3"
                    fontSize={20}
                    className="bg-gray-100 px-4 py-2 !font-black tracking-wider uppercase text-gray-500"
                  >
                    Profile
                  </Heading>

                  <article className="flex flex-col gap-4">
                    {data.proPortfolio.overview ? (
                      data.proPortfolio.overview
                        .split("\n\n")
                        .map((para, index) => <p key={index}>{para}</p>)
                    ) : (
                      <p className="text-gray-500">No overview provided</p>
                    )}
                  </article>
                </div>
                {!!data.proPortfolio.certifications.length && (
                  <div className="p-6 flex flex-col items-start gap-4 border-b ">
                    <Heading
                      as="h3"
                      fontSize={20}
                      className="bg-gray-100 px-4 py-2 !font-black tracking-wider uppercase text-gray-500"
                    >
                      Certifications &amp; Licenses
                    </Heading>

                    {data.proPortfolio.certifications.length ? (
                      <div className="flex flex-col gap-4">
                        {data.proPortfolio.certifications.map(
                          (certification, index, array) => (
                            <div
                              key={index}
                              className={clsx(
                                "flex flex-col gap-4  py-4",
                                index !== array.length - 1 && "border-b"
                              )}
                            >
                              <div className="flex flex-col gap-2">
                                <div className="flex flex-col lg:flex-row items-start lg:items-center  gap-1">
                                  <Heading
                                    as="h4"
                                    fontSize={18}
                                    className="font-medium m-0"
                                  >
                                    {certification.name}
                                  </Heading>

                                  <div className="flex  items-center gap-1">
                                    <CheckCircle2 className="fill-flatteredFlamingo-500 stroke-white" />
                                    <p>Verified</p>
                                  </div>
                                </div>

                                <p>{certification.issuedBy}</p>
                              </div>
                              <p className="text-sm text-gray-500">
                                Issued{" "}
                                {format(
                                  new Date(certification.issuedAt),
                                  "MMM dd, yyyy"
                                )}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <p className="text-gray-500">
                        No certifications provided
                      </p>
                    )}
                  </div>
                )}
                {!!data.proPortfolio.jobs.length && (
                  <div className="p-6 flex flex-col gap-4 border-b items-start">
                    <Heading
                      as="h3"
                      fontSize={20}
                      className="bg-gray-100 px-4 py-2 !font-black tracking-wider uppercase text-gray-500"
                    >
                      Work History
                    </Heading>

                    {data.proPortfolio.jobs.length ? (
                      <WorkHistory jobs={data.proPortfolio.jobs} />
                    ) : (
                      <p className="text-gray-500">No work history yet</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Stack>
      </HStack>
    </>
  );
}

import React, {
  ElementType,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Avatar,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import about_us from "./about_us.svg";
import career from "./career.svg";
import faqs from "./faqs.svg";
import contact_us from "./contact_us.svg";
import BrandLogo from "../../assets/logos/brand.svg";
import WhiteLogo from "../../assets/logos/white.svg";
import clsx from "clsx";
import { BiX } from "react-icons/bi";
import { FaBlog } from "react-icons/fa";
import { useAuth0, User } from "@auth0/auth0-react";
import { LoggedInMenu } from "./logged-in-menu";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { LoggedInMenuContent } from "./logged-in-menu-content";

const GET_PROFILES = gql`
  query GetUserProfiles {
    myPro {
      id
    }
    myClient {
      id
    }
  }
`;

type NavbarTheme = "default" | "flatteredFlamingo";

interface NavbarThemeProps {
  bg: string;
  textColor: string;
  inactiveTextColor: string;
  logo: string;
  borderColor: string;
  btn: {
    colorScheme: string;
    bg: string;
    textColor: string;
  };
}

const colors: Record<NavbarTheme, NavbarThemeProps> = {
  flatteredFlamingo: {
    bg: "flatteredFlamingo.500",
    textColor: "white",
    inactiveTextColor: "white",
    logo: WhiteLogo,
    borderColor: "white",
    btn: {
      colorScheme: "white",
      bg: "white",
      textColor: "flatteredFlamingo.500",
    },
  },
  default: {
    bg: "white",
    textColor: "black",
    inactiveTextColor: "inactiveTextColor",
    logo: BrandLogo,
    borderColor: "gray-900",
    btn: {
      colorScheme: "flatteredFlamingo",
      bg: "flatteredFlamingo.500",
      textColor: "white",
    },
  },
};

interface NavbarProps {
  banner?: ElementType;
  colorScheme?: NavbarTheme;
}

interface NavbarLinkProps {
  isActive: boolean;
  url: string;
  themeProps: NavbarThemeProps;
  text: string;
}

const menu = {
  homepage: {
    url: "/",
  },
  business: {
    url: "/business",
  },
  homeOwners: {
    url: "/homeowners",
  },
  artisans: {
    url: "/artisans",
  },
  aboutUs: {
    url: "/about",
  },
  career: {
    url: "https://laborhack.grovehr.com/careers",
  },
  faqs: {
    url: "/faqs",
  },
  contactUs: {
    url: "/contact",
  },
  blog: {
    url: "https://blog.laborhack.com/",
  } /*
  materials: {
    url: "https://bit.ly/40n5Pv8",
  },*/,
  login: {
    url: "/auth/login/homeowner",
  },
  register: {
    url: "/auth/register",
  },
  subscriptions: {
    url: "/subscriptions",
  },
};

const NotLoggedInMenu = ({
  theme,
}: PropsWithChildren<{ theme: NavbarThemeProps }>) => (
  <Flex
    alignItems={"center"}
    justify="end"
    display={{
      base: "none",
      md: "none",
      lg: "none",
      xl: "flex",
      width1279px: "none",
      width1428px: "flex",
    }}
    gap={4}
  >
    <Button
      as={RouterLink}
      to={menu.login.url}
      height="auto"
      borderColor={theme.borderColor}
      color={theme.textColor}
      variant="outline"
      py={4}
      px={10}
      border="2px solid"
      fontSize={20}
      fontStyle="normal"
      fontWeight="500"
      _hover={{ textDecoration: "none", color: theme.textColor }}
    >
      Login
    </Button>
    <Button
      as={RouterLink}
      to={menu.register.url}
      height="auto"
      borderColor={theme.btn.colorScheme}
      colorScheme={theme.btn.colorScheme}
      bg={theme.btn.bg}
      color={theme.btn.textColor}
      py={4}
      px={10}
      border="2px solid"
      fontSize={20}
      fontStyle="normal"
      fontWeight="500"
      _hover={{ textDecoration: "none", color: theme.btn.textColor }}
    >
      Sign Up
    </Button>
  </Flex>
);

const UserMenu = ({
  isClient,
  isPro,
  isLoading,
  theme,
  user,
  logout,
}: PropsWithChildren<{
  isClient: boolean;
  isPro: boolean;
  isLoading?: boolean;
  theme: NavbarThemeProps;
  user?: User;
  logout: () => void;
}>) => {
  if (isLoading) {
    return <Skeleton height="6.75rem" />;
  }

  if (!user) return <NotLoggedInMenu theme={theme} />;

  return (
    <LoggedInMenu
      user={user!}
      isClient={isClient}
      isPro={isPro}
      logout={logout}
    />
  );
};

const NavbarLink = ({ isActive, url, themeProps, text }: NavbarLinkProps) => {
  return (
    <Link
      as={RouterLink}
      to={url}
      color={isActive ? themeProps.textColor : themeProps.inactiveTextColor}
      fontSize="20px"
      fontStyle="normal"
      fontWeight={500}
      lineHeight="28px"
      _hover={{ textDecoration: "none", color: themeProps.textColor }}
      className={clsx(
        `border-b-4 border-transparent hover:border-${themeProps.borderColor}`,
        isActive && `border-${themeProps.borderColor}`
      )}
    >
      <Text>{text}</Text>
    </Link>
  );
};

const Navbar: React.FC<NavbarProps> = ({ banner: Banner, colorScheme }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const [searchingForExistingAccessToken, setSearchingForExistingAccessToken] =
    useState(false);

  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } =
    useAuth0();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated) {
      setSearchingForExistingAccessToken(true);

      getAccessTokenSilently()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setSearchingForExistingAccessToken(false);
        });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const [getMyProfiles, getMyProfilesHandle] =
    useLazyQuery<{
      myPro: any;
      myClient: any;
    }>(GET_PROFILES);

  useEffect(() => {
    if (isAuthenticated) {
      getMyProfiles();
    }
  }, [isAuthenticated]);

  const isActivePage = (urlToCheck: string) => {
    return location.pathname === urlToCheck;
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const theme = colors[colorScheme || "default"];

  const companyMenuActive =
    isActivePage(menu.aboutUs.url) ||
    isActivePage(menu.faqs.url) ||
    isActivePage(menu.contactUs.url);

  const profiles = getMyProfilesHandle.data;

  return (
    <Stack bg={theme.bg} position="sticky" width="full" zIndex={999} top={0}>
      <Flex
        px={5}
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
        height="6.75rem"
        m={{ base: 0, xl: "0 90px" }}
        display={isOpen ? "none" : "flex"}
      >
        <RouterLink to="/">
          <Image src={theme.logo} alt="LaborHack" height="24px" width="174px" />
        </RouterLink>
        <Flex
          display={{
            base: "none",
            md: "none",
            lg: "none",
            xl: "flex",
            width1279px: "none",
            width1428px: "flex",
          }}
          alignItems="center"
          justify="center"
          maxWidth="60%"
          margin="auto"
          gap={8}
        >
          <NavbarLink
            isActive={isActivePage(menu.business.url)}
            url={menu.business.url}
            themeProps={theme}
            text="Businesses"
          />
          <NavbarLink
            isActive={isActivePage(menu.homeOwners.url)}
            url={menu.homeOwners.url}
            themeProps={theme}
            text="Homeowners"
          />
          <NavbarLink
            isActive={isActivePage(menu.artisans.url)}
            url={menu.artisans.url}
            themeProps={theme}
            text="Artisans"
          />
          <NavbarLink
            isActive={isActivePage(menu.subscriptions.url)}
            url={menu.subscriptions.url}
            themeProps={theme}
            text="Subscriptions"
          />
          <Menu>
            <MenuButton
              as={Button}
              variant="link"
              rightIcon={<ChevronDownIcon />}
              bgColor="transparent"
              color={
                companyMenuActive ? theme.textColor : theme.inactiveTextColor
              }
              fontSize="20px"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="28px"
              textDecoration="none"
              _hover={{ textDecoration: "none", color: theme.textColor }}
              className={clsx(
                "border-b-4 border-transparent hover:border-white",
                companyMenuActive && "border-white"
              )}
            >
              Company
            </MenuButton>
            <MenuList
              color="white"
              display="Grid"
              width="612px"
              position="relative"
              right={{
                base: "21rem",
                md: "21rem",
                lg: "21rem",
                xl: "21rem",
                "2xl": "21rem",
              }}
              top="21px"
              boxShadow="-1px 12px 34px -17px rgba(183, 183, 183, 0.70)"
              zIndex={9999}
              css={{
                "@media (min-width: 1280px) and (max-width: 1375px)": {
                  right: "-21rem",
                },
              }}
            >
              <MenuItem color="black" onClick={() => {}} gridColumn="1">
                <Flex color="black" onClick={() => {}} p="16px">
                  <HStack as={RouterLink} to="/about" spacing={18}>
                    <Image src={about_us} alt="About Us" />
                    <Box>
                      <Text
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="21px"
                      >
                        {" "}
                        About Us
                      </Text>
                      <Text
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="21px"
                      >
                        Learn more about who we are
                      </Text>
                    </Box>
                  </HStack>
                </Flex>
              </MenuItem>
              <MenuItem color="black" gridColumn="2">
                <Flex color="black" onClick={() => {}} p="16px">
                  <HStack
                    as={"a"}
                    href="https://laborhack.grovehr.com/careers"
                    spacing={18}
                  >
                    <Image src={career} alt="Career" />
                    <Box>
                      <Text
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="21px"
                      >
                        {" "}
                        Career
                      </Text>
                      <Text
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="21px"
                      >
                        Join the LaborHack team
                      </Text>
                    </Box>
                  </HStack>
                </Flex>
              </MenuItem>
              <MenuItem color="black" onClick={() => {}} gridColumn="1/span1">
                <Flex color="black" onClick={() => {}} p="16px">
                  <HStack as={RouterLink} to="/faqs" spacing={18}>
                    <Image src={faqs} alt="Faqs" />
                    <Box>
                      <Text
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="21px"
                      >
                        {" "}
                        FAQs
                      </Text>
                      <Text
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="21px"
                      >
                        Get answers to your questions
                      </Text>
                    </Box>
                  </HStack>
                </Flex>
              </MenuItem>
              <MenuItem color="black" onClick={() => {}} gridColumn="2/span 1">
                <Flex color="black" onClick={() => {}} p="16px">
                  <HStack as={RouterLink} to="/contact" spacing={18}>
                    <Image src={contact_us} alt="Contact Us" />
                    <Box>
                      <Text
                        fontSize="20px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="21px"
                      >
                        {" "}
                        Contact Us
                      </Text>
                      <Text
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="21px"
                      >
                        Reach out to us anytime
                      </Text>
                    </Box>
                  </HStack>
                </Flex>
              </MenuItem>
              <MenuItem color="black" onClick={() => {}} gridColumn="1/span 1">
                <Flex color="black" onClick={() => {}} py="16px" px="24px">
                  <LinkBox>
                    <LinkOverlay href={menu.blog.url}>
                      <HStack spacing={18}>
                        <Icon as={FaBlog} w={6} h={6} />
                        <Box>
                          <Text
                            fontSize="20px"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="21px"
                          >
                            Blog
                          </Text>
                          <Text
                            fontSize="14px"
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="21px"
                          >
                            Get the latest updates
                          </Text>
                        </Box>
                      </HStack>
                    </LinkOverlay>
                  </LinkBox>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Flex
          display={{
            base: "none",
            md: "none",
            lg: "none",
            xl: "flex",
            width1279px: "none",
            width1428px: "flex",
          }}
        >
          <UserMenu
            isClient={!!profiles?.myClient}
            isPro={!!profiles?.myPro}
            isLoading={isLoading || searchingForExistingAccessToken}
            user={user}
            theme={theme}
            logout={handleLogout}
          />
        </Flex>

        <IconButton
          display={{
            base: "flex",
            md: "flex",
            lg: "flex",
            xl: "none",
            width1279px: "flex",
            width1428px: "none",
          }}
          onClick={() => onOpen()}
          icon={<HamburgerIcon boxSize={6} />}
          aria-label={""}
          bgColor="transparent"
          color={theme.btn.bg}
        />
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay>
          <DrawerContent>
            <div className="h-dvh bg-white flex flex-col">
              <div className="flex justify-between items-center p-4">
                <Image
                  src="./new-logo.svg"
                  alt="LaborHack"
                  height="24px"
                  width="174px"
                />
                <IconButton
                  isRound={true}
                  variant="outline"
                  aria-label="close"
                  fontSize="24px"
                  borderColor="gray.300"
                  icon={
                    <Icon
                      as={BiX}
                      w={{
                        base: 6,
                        lg: 8,
                      }}
                      h={{
                        base: 6,
                        lg: 8,
                      }}
                    />
                  }
                  onClick={onClose}
                />
              </div>
              <div className="flex-1 py-4">
                <Stack spacing={0}>
                  <Link
                    as={RouterLink}
                    to="/business"
                    p="16px"
                    borderBottom="1px solid #F3F3F3"
                    fontFamily="AeonikPro"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="24px"
                    _hover={{ textDecoration: "none", color: "Inherit" }}
                    onClick={onClose}
                  >
                    Businesses
                  </Link>
                  <Link
                    as={RouterLink}
                    to="/homeowners"
                    p="16px"
                    borderBottom="1px solid #F3F3F3"
                    fontFamily="AeonikPro"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="24px"
                    _hover={{ textDecoration: "none", color: "Inherit" }}
                    onClick={onClose}
                  >
                    Homeowners
                  </Link>
                  <Link
                    as={RouterLink}
                    to="artisans"
                    p="16px"
                    borderBottom="1px solid #F3F3F3"
                    fontFamily="AeonikPro"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="24px"
                    _hover={{ textDecoration: "none", color: "Inherit" }}
                    onClick={onClose}
                  >
                    Artisans
                  </Link>
                  <Link
                    as={RouterLink}
                    to="subscriptions"
                    borderBottom="1px solid #F3F3F3"
                    p="16px"
                    fontFamily="AeonikPro"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="24px"
                    _hover={{ textDecoration: "none", color: "Inherit" }}
                    onClick={onClose}
                  >
                    Subscriptions
                  </Link>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton p={0} pr={4}>
                        <Box
                          flex="1"
                          textAlign="left"
                          p="16px"
                          color="#0E0302"
                          fontFamily="AeonikPro"
                          fontSize="18px"
                          fontStyle="normal"
                          fontWeight={500}
                          lineHeight="24px"
                          _hover={{ textDecoration: "none", color: "Inherit" }}
                        >
                          Company
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Flex color="black" onClick={() => {}} p="16px">
                          <HStack
                            as={RouterLink}
                            to="/about"
                            spacing={18}
                            _hover={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            onClick={onClose}
                          >
                            <Image src={about_us} alt="About Us" />
                            <Box>
                              <Text
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="21px"
                              >
                                {" "}
                                About Us
                              </Text>
                              <Text
                                fontSize="12px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="19px"
                                color="#5E5E5E"
                              >
                                Learn more about who we are
                              </Text>
                            </Box>
                          </HStack>
                        </Flex>
                        <Flex color="black" onClick={() => {}} p="16px">
                          <HStack
                            as={"a"}
                            href="https://laborhack.grovehr.com/careers"
                            spacing={18}
                            onClick={onClose}
                          >
                            <Image src={career} alt="Career" />
                            <Box>
                              <Text
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="21px"
                              >
                                {" "}
                                Career
                              </Text>
                              <Text
                                fontSize="12px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="19px"
                                color="#5E5E5E"
                              >
                                Join the LaborHack team
                              </Text>
                            </Box>
                          </HStack>
                        </Flex>
                        <Flex color="black" onClick={() => {}} p="16px">
                          <HStack
                            as={RouterLink}
                            to="/faqs"
                            spacing={18}
                            onClick={onClose}
                          >
                            <Image src={faqs} alt="Faqs" />
                            <Box>
                              <Text
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="21px"
                              >
                                {" "}
                                FAQs
                              </Text>
                              <Text
                                fontSize="12px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="19px"
                                color="#5E5E5E"
                              >
                                Get answers to your questions
                              </Text>
                            </Box>
                          </HStack>
                        </Flex>
                        <Flex color="black" p="16px">
                          <HStack
                            spacing={18}
                            as={RouterLink}
                            to="/contact"
                            onClick={onClose}
                          >
                            <Image src={contact_us} alt="Contact Us" />
                            <Box>
                              <Text
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="21px"
                              >
                                {" "}
                                Contact Us
                              </Text>
                              <Text
                                fontSize="12px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="19px"
                                color="#5E5E5E"
                              >
                                Reach out to us anytime
                              </Text>
                            </Box>
                          </HStack>
                        </Flex>
                        <Flex color="black" onClick={() => {}} p="16px">
                          <HStack
                            as={"a"}
                            href={menu.blog.url}
                            spacing={18}
                            onClick={onClose}
                          >
                            <Image src={career} alt="Career" />
                            <Box>
                              <Text
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="21px"
                              >
                                Blog
                              </Text>
                              <Text
                                fontSize="12px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="19px"
                                color="#5E5E5E"
                              >
                                Get the latest updates
                              </Text>
                            </Box>
                          </HStack>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  {!user && (
                    <Link
                      as={RouterLink}
                      to={menu.login.url}
                      p="16px"
                      borderBottom="1px solid #F3F3F3"
                      fontFamily="AeonikPro"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={500}
                      lineHeight="24px"
                      _hover={{ textDecoration: "none", color: "inherit" }}
                      onClick={onClose}
                    >
                      Login
                    </Link>
                  )}
                </Stack>
              </div>
              <div className="flex flex-col p-4">
                {!user ? (
                  <Button
                    as={RouterLink}
                    to={menu.register.url}
                    colorScheme="flatteredFlamingo"
                    size="lg"
                  >
                    Sign Up
                  </Button>
                ) : (
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton p={0} pr={4}>
                        <Box
                          flex="1"
                          textAlign="left"
                          p="16px"
                          color="#0E0302"
                          fontFamily="AeonikPro"
                          fontSize="18px"
                          fontStyle="normal"
                          fontWeight={500}
                          lineHeight="24px"
                          _hover={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Flex alignItems="center" gap={4}>
                            <Avatar
                              size="sm"
                              name={user?.name}
                              src={user?.picture}
                            />
                            <h5 className="text-lg">
                              Hi, {user?.given_name || user.nickname} <br />
                            </h5>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <LoggedInMenuContent
                          email={user?.email || ""}
                          isClient={!!profiles?.myClient}
                          isPro={!!profiles?.myPro}
                          logout={handleLogout}
                          className="px-0"
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </div>
            </div>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      {Banner && <Banner width="full" display={isOpen ? "none" : "flex"} />}
    </Stack>
  );
};

export default Navbar;
